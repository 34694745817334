<template>
  <v-app id="inspire">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-content>
      <v-container fluid>

        <!-- <v-container fluid> -->
        Please Wait , <br>
        Redirecting you to Library Portal
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>


      </v-container>
    </v-content>
  </v-app>
</template>
<script>

import axios from "axios";
import crypto from '@/service/crypto'

export default {
  data: () => ({
    dialog: false,
    search: "",
    snackbar: "",
    snackbar_msg: "",
    color: "",
    valid: false,

    usertype: null,
    overlay: false,



  }),

  mounted() {
    localStorage.clear();
    this.overlay = true;
    const params = {
      uid: this.$route.query.siufmhsdhfshdfhsjkdf.toString().replaceAll(" ", "+"),
      org: this.$route.query.wuehruiwuehqjldjalkk.toString().replaceAll(" ", "+"),
      tenant: this.$route.query.shwuerweuuwiids.toString().replaceAll(" ", "+"),
    }



    let enc = crypto.encrypt(params);
    var params1 = { data: enc }
    axios
      .post("/login/InstToLibrary", params1)
      .then((res) => {
        if (res.data.msg == "200") {

          // localStorage.setItem("awspath", res.data.awspath)
          localStorage.setItem("EPS-token", res.data.token);
          localStorage.setItem("EPS-uid", res.data.uid);
          localStorage.setItem("logo_url", res.data.logo_url);
          localStorage.setItem("org_name", res.data.org_name);
          localStorage.setItem("org_trust_name", res.data.org_trust_name);
          localStorage.setItem("org_address", res.data.org_address);
          if (res.data.policy_read == true) {

            if (res.data.firstlogin == "1") {
              localStorage.setItem("first-login", res.data.firstlogin);
              this.$router.push("/update-password");
            } else {
              localStorage.setItem("first-login", "0");
              this.$router.push("/pay-fine-new");
            }
          } else {
            this.dialog = true
          }
          //window.location="/home"
          this.$router.push({ name: "pay-fine-new" }).catch(() => { });

        } else if(res.data.msg == "flag"){
          localStorage.setItem("EPS-token", res.data.token);
          localStorage.setItem("EPS-uid", res.data.uid);
          localStorage.setItem("logo_url", res.data.logo_url);
          localStorage.setItem("org_name", res.data.org_name);
          localStorage.setItem("org_trust_name", res.data.org_trust_name);
          localStorage.setItem("org_address", res.data.org_address);
          this.$router.push("/home");
          this.overlay = false;
        }
        else{
          this.showSnackbar('red', res.data.msg)
        }
      })


  },
  methods: {

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },


  },
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 30px 0;
  text-align: center;
}

.center2 {
  margin: auto;
  width: 50%;
  padding: 40px 0;
  text-align: left;
}

.question {
  margin: 8px;
  height: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
</style>